import { StackProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import { mergeSx } from '@jucy-ui/appearance';
import { CTAButton } from '@/components/CTAButton';
import { useSiteLinkPrefix } from '@/hooks/useSiteLinkPrefix';
import { CmsLink } from '@/services/cms/models/CmsLink';

export interface CTAsProps extends StackProps {
    links: CmsLink[];
}

export const CTAs = ({ links, gap, direction, sx, ...props }: CTAsProps) => {
    const addSiteLinkPrefix = useSiteLinkPrefix();
    return (
        <Stack direction={direction || 'row'} {...props} sx={mergeSx({ gap: gap || 2 }, sx)}>
            {links.map((link) => (
                <CTAButton key={link.id} variant="contained" href={addSiteLinkPrefix(link.url)} target={link.openInNew ? '_blank' : undefined}>
                    {link.title}
                </CTAButton>
            ))}
        </Stack>
    );
};
