export type InteractionEvent = {
    interaction_category?: string;
    interaction_action?: string;
    interaction_label?: string;
    interaction_source: string;
};

export type EventParams = {
    interaction: InteractionEvent;
};

export type EventType = keyof EventParams;
export const gtmTriggerEvent = <T extends EventType>(event: T, params: EventParams[T]) => {
    if (typeof window === 'undefined') {
        return;
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event,
        ...params,
    });
};

export type GaEcommerceEventParams = Gtag.EventParams & {
    item_list_id?:string;
    item_list_name?:string;
}
export const gtmTriggerEcommerceEvent = (event: Gtag.EventNames, params:GaEcommerceEventParams) => {
    if (typeof window === 'undefined') {
        return;
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event,
        ecommerce: params,
    });
};
