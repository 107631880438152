'use client';

import * as React from 'react';
import { AccordionProps, styled } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';

export const JucyMuiAccordion = styled(({ children, ...props }: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square slotProps={{ heading: { component: 'div' } }} {...props}>
        {children}
    </MuiAccordion>
))(() => ({
    padding: 0,
    '&:before': {
        display: 'none',
    },
}));
